import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import Img1 from "../images/car-icon.png";
import Img2 from "../images/driver-icon.png";
import Img3 from "../images/airport-icon.png";

const ThreeColWithImageHover = ({ Data = null, SectionTitle }) => {
  const defaultData = [
    {
      title: "Intercity rides",
      description: "Your stress-free solution for traveling between cities,r",
      img: Img1,
      rating: "5.0",
      price: "$250",
      url: {},
    },
    {
      title: "  chauffeur hailing",
      description: "Your stress-free solution for traveling between cities,r",
      img: Img2,
      rating: "5.0",
      price: "$460",
      url: {},
    },
    {
      title: "Airport transfers ",
      description: "Your stress-free solution for traveling between cities,r",
      img: Img3,
      rating: "4.5",
      price: "$670",
      url: {},
    },
  ];

  if (!Data) Data = defaultData;
  return (
    <div className="section__gap threecolwithimage__wrapper ">
      <Container fluid>
        <div className="text-center ">
        {SectionTitle && <h2 className="section__title text-uppercase">{SectionTitle}</h2>}
          
          <p>
            ACTON MA TO BOSTON AIRPORT TRANSFER: We provide reliable and on time
            airport taxi service from Acton MA to Boston Logan Airport
            International Airport, Acton MA to Logan Airport, Acton to
            Manchester, Acton to Portland ME International Jetport, Acton to
            Providence RI TF Green/Warwick, Acton to Bradley CT Intl, Acton to
            Burlington VT Airport. All our rates are affordable and there are no
            hidden charges as well.
          </p>
        </div>
        <div className="text-center">
          <Row>
            {Data.map((info, i) => (
              <Col className="threecolwithimagehover__unit"   lg="4" key={i}>
                 
                <img src={info.img} alt={info.title || ""} />
                <h4>{info.title || ""}</h4>
                <p className="description">{info.description || ""}</p>
              </Col>
            ))}
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default ThreeColWithImageHover;
