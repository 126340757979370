import React from "react";
import Nav from "../components/NavComp";
import InnerBanner from "../components/InnerBanner";
import { Col, Container, Row } from "react-bootstrap";
import Banner from "../images/about-banner.png";
import FooterComp from "../components/FooterComp";

import ListGroup from "react-bootstrap/ListGroup";
const ServiceArea = () => {
  return (
    <>
      <Nav
        title="Acton to Logan airport taxi services – Acton Airport Taxi"
        desc="Reliable taxi services with affordable fares from Acton to Logan International airport in Massachusetts. Unbeatable prices and services we offer."
      />
      <InnerBanner BannerImage={Banner} />
      <div className="section__gap ">
        <Container fluid>
          <h2 className=" text-center section__title centered hav__icon mb-5">
            Services
          </h2>
          <Row>
            <Col lg="12">
              <h4 className="mb-4   md__title">
                <strong> Taxis Services for Over 25 Years </strong>
              </h4>
              <p>
                <strong>ACTON MA TO BOSTON AIRPORT TRANSFER</strong>: We provide
                reliable and on time airport taxi service from{" "}
                <strong>
                  Acton MA to Boston Logan Airport International Airport, Acton
                  MA to Logan Airport, Acton to Manchester, Acton to Portland ME
                  International Jetport, Acton to Providence RI TF
                  Green/Warwick, Acton to Bradley CT Intl, Acton to Burlington
                  VT Airport.
                </strong>{" "}
                All our rates are affordable and there are no hidden charges as
                well.
              </p>
              <h4 className="mb-4   small__title">
                Cost Effective Trusted Logan Airport Taxi Cab
              </h4>
              <p>
                <strong>ACTON MA TO LOGAN AIRPORT TRANSFER</strong>: Acton Taxi
                Service is providing the affordable and reliable pick and drop
                off taxi service from Acton MA to Logan airport transfer.
              </p>
              <p>
                Our professional and experienced chauffeur will meet you inside
                the terminal with your name sign. They will assist with your
                luggage and will take you to your destination. All our vehicles
                are luxurious and comfortable and thereare no hidden charges as
                well.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
            <h4 className="mb-4   small__title">
              <strong>  Our Services</strong>
              </h4>
              <ListGroup className="box-list-inline">
                <ListGroup.Item>
                  <a href="/boston-airport-taxi">Boston Airport Taxi</a>
                </ListGroup.Item>
                 
                <ListGroup.Item>
                  <a href="/boston-airport-shuttle">Boston Airport Shuttle</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/acton-ma-to-boston-airport-transfer">Acton MA To Boston Airport Transfer </a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/acton-ma-to-boston-logan-airport-international-airport">
                    Acton MA To Boston Logan Airport International Airport
                  </a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/acton-ma-to-logan-airport-transfer">Acton MA To Logan Airport Transfer</a>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </Container>
      </div>

      <FooterComp />
    </>
  );
};
export default ServiceArea;
