import React from "react";
import Nav from "../components/NavComp";
import InnerBanner from "../components/InnerBanner";
import { Col, Container, Row } from "react-bootstrap";
import Banner from "../images/about-banner.png";
import FooterComp from "../components/FooterComp";

const ServiceArea = (props) => {
  return (
    <>
      <Nav title={props.metatitle} desc={props.metadesc} />
      <InnerBanner BannerImage={Banner} />
      <div className="section__gap ">
        <Container fluid>
          <h2 className=" text-center section__title centered hav__icon mb-5">
            {props.title}
          </h2>
          <Row>
            <Col lg="12">
              <p>{props.desc1}</p>
              <p>{props.desc2}</p>
            </Col>
          </Row>
        </Container>
      </div>
      <FooterComp />
    </>
  );
};
export default ServiceArea;
