import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ListGroup from "react-bootstrap/ListGroup";

import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

import logo from "../images/logo.png";

const FooterComp = () => {
  const today = new Date();
  const year = today.getFullYear();
  return (
    <>
      <footer>
        <Container fluid>
          <div className="suscribe-part">
            <h3 className="section__title text-center text-white">
              Traveling to Boston or Logan from <br /> your{" "}
              <span>Location</span> ?
            </h3>
            <p>
              Cheap and expensive is a relative word. We always keep our price
              lower than any other company in the city and we ensure you the
              best prices in the city.
            </p>
            <a href="" className="btn__main btn__rounded">
              Subscribe Now
            </a>
          </div>
          <Row>
            <Col lg="3">
              <img src={logo} />
              <p>
                A place to change all your hair styles at a very cheap and great
                price
              </p>
              <p>
                <strong>Office: </strong>
                Concord Airport Taxi & Car Services 300 Baker Avenue, Concord,
                MA 01742
              </p>
              <p>
                <strong>Phone: </strong>
                <a href="tel:978-371-2224">978-371-2224</a>
              </p>
              <p>
                <strong>Email: </strong>
                <a href="mailto:info@actonairporttaxi.com">
                  info@actonairporttaxi.com
                </a>
              </p>
            </Col>
            <Col lg="2">
              <h4>Useful Links</h4>
              <ListGroup>
                <ListGroup.Item>
                  <a href="/about">About</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/services">Service</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/our-fleets">Fleet</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/contact">Contact</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/terms-and-conditions">Terms & Conditions</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/privacy-policy">Privacy policy</a>
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col lg="3">
              <h4>Our Services</h4>
              <ListGroup>
                <ListGroup.Item>
                  <a href="/boston-airport-taxi">Boston Airport Taxi</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/boston-airport-shuttle">Boston Airport Shuttle</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/acton-ma-to-boston-airport-transfer">
                    Acton MA To Boston Airport Transfer
                  </a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/acton-ma-to-boston-logan-airport-international-airport">
                    Acton MA To Boston Logan Airport International Airport
                  </a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/acton-ma-to-logan-airport-transfer">
                    Acton MA To Logan Airport Transfer
                  </a>
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col lg="4">
              <h4>Location We Serve</h4>
              <ListGroup className="list__count__2">
                <ListGroup.Item>
                  <a href="/acton">Acton</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/boston">Boston</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/framingham">Framingham</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/hudson">Hudson</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/marlborough">Marlborough</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/maynard">Maynard</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/natick">Natick</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/northborough">Northborough</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/sudbury">Sudbury</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/wayland">Wayland</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/westborough">Westborough</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/weston">Weston</a>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
          <hr />
          <div className="copyright">
            <p>
              Copyright {year} Action Airport Cars Limited. All rights reserved.
            </p>
            <div className="social-icons">
              <a href="#!">
                <i class="fa-brands fa-facebook-f"></i>
              </a>
              <a href="#!">
                <i class="fa-brands fa-twitter"></i>
              </a>
              <a href="#!">
                <i class="fa-brands fa-instagram"></i>
              </a>
            </div>
          </div>
        </Container>
      </footer>
    </>
  );
};
export default FooterComp;
