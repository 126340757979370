import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import SmLogo from "../images/sm-logo.png";

const ThreeColWithLogoDark = () => {
  return (
    <>
      <div className="section__gap  pt-0">
        <Container fluid>
          <div className="homepage-more-about">
            <img src={SmLogo} alt="" />

            <p>
            With the immaculate wide range of vehicles, we can accommodate your transportation request. 
            </p>

            <a href="#!">
              <i class="fa-solid fa-arrow-right"></i>
            </a>
          </div>
        </Container>
      </div>
    </>
  );
};
export default ThreeColWithLogoDark;
