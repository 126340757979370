import React from "react";
const LeftIconMediaPlain = (props) => {
  return (
    <>
      <div className="custom__media__plain">
        <div className="left__part">
          <img src={props.icon} alt="image" />
        </div>
        <div className="right__part"> 
          <p>{props.description}</p>
        </div>
      </div>
    </>
  );
};

export default LeftIconMediaPlain;
