import React from "react";

import Nav from "../components/NavComp";
import Banner from "../components/MainBannerComp";
import ThreeColWithImage from "../components/ThreeColWithImage";
import TwoColAppSection from "../components/TwoColAppSection";
import ReviewSlider from "../components/ReviewSlider";
import FooterComp from "../components/FooterComp";
import ThreeColWithLogoDark from "../components/ThreeColWithLogoDarkBg";
import ThreeColWithImageHover from "../components/ThreeColWithImageHover";
const Index = () => {
  return (
    <>
      <Nav
        title="Taxi to Boston International Airport | Low Cost Fares"
        desc="Acton Airport taxi is providing taxi transportation from Acton MA to Boston International Airport & Logan Airport at an affordable price. Call 978-268-0700"
      />
      <Banner />
      <ThreeColWithImage SectionTitle="Our Fleet" />
      <ThreeColWithLogoDark />
      <TwoColAppSection />
      <ReviewSlider SectionTitle="What Our Clients are saying About us" />
      <ThreeColWithImageHover SectionTitle="Our services" />
      <FooterComp />
    </>
  );
};

export default Index;
