import logo from "./logo.svg";
import "./App.css";
import "./Responsive.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Index from "./pages/Index";
import About from "./pages/About";
import Service from "./pages/Service";
import Fleet from "./pages/Fleet";
import Contact from "./pages/Contact";
import ServiceArea from "./pages/ServiceArea";
import ServiceDetails from "./pages/ServiceDetails";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/services">
          <Service />
        </Route>
        <Route path="/our-fleets">
          <Fleet />
        </Route>
        <Route path="/contact">
          <Contact />
        </Route>
        <Route path="/acton">
          <ServiceArea
            metatitle="Acton Airport Taxi and Car Services - Town Car Service"
            metadesc="Specialties: Affordable Taxi Service in Acton, MA to Logan Airport & Acton MA to Boston International Airport. Contact for instant Bookings."
            title="Acton MA"
            desc1="Acton Airport Taxi provides a reliable and comfortable taxi and limo service in Acton MA. We have cabs which suit every pocket from low-cost car to a luxurious car. You can make a booking from Boston Airport to any cities and from any cities to Boston Airport.  We provide service in best pricing as you can make an online booking with an initial payment of $1 and the due amount can be paid later on arrival.   Payment options are PayPal and Credit Cards"
            desc2=" We assure you to be picked up and drop off on time at anywhere in Acton, Boston and its surrounding areas.  We have hired verified and experienced drivers to provide best and safe travel service to our customers in their destinations. We are available at your transportation 24/7 service 365 days."
          />
        </Route>
        <Route path="/boston">
          <ServiceArea
            metatitle="Boston Airport Taxi & Shuttle Service | 24/7 Reliable and Clean Taxi"
            metadesc="Get 20% Off Online Booking Now. Credit Card Accepted Call Now. No Hidden Fees. No Charge for Gate Fees. Professional Drivers. Book Online & Get 20% Off on Boston Airport Taxi & Shuttle"
            title="Boston MA "
            desc1="Acton Airport Taxi provides easy and convenient taxi and limo transportation service in Boston MA. There is a ride for every occasion and every budget. You can choose what’s best for you from our various categories of fleets with the best pricing. You can make a booking from Boston Airport to any cities and from any cities to Boston Airport. Passengers can make a booking by our mobile app and website to their preferred date and time around the Acton, Boston and its surrounding areas. "
            desc2="Acton Airport Taxi provides various payment options like PayPal and Card. Passenger can make an online booking by paying an initial amount of $1 and the remaining amount later on arrival. We have experienced drivers to have a safe ride experience. We make sure our passenger reaches their destination safely."
          />
        </Route>
        <Route path="/framingham">
          <ServiceArea
            metatitle="Framingham Car Service - Framingham Airport Shuttle Transportation"
            metadesc="Enjoy a comfortable and convenient ride to and from the airport with Acton Airport Taxi service in Framingham."
            title="Framingham MA"
            desc1="Acton Airport Taxi provides a reliable and comfortable taxi and limo service in Framingham MA. Our quick and easy to use website allows you to book your cab in real time with low and best price. Our wide range of fleets perfectly suits every customer needs as desired. The initial payment can be made for $1 after booking your cab and rest due amount can be paid after the drop-off. Payment options are PayPal and Credit Cards. We assure you to be picked up and drop off on time at anywhere in Acton, Framingham, Boston and its surrounding areas. "
            desc2=" "
          />
        </Route>
        <Route path="/hudson">
          <ServiceArea
            metatitle="Hudson Airport Taxi Services – Limos Service in Hudson"
            metadesc="Acton Taxi (978) 268-0700 provides 24/7 Reliable, Low-Cost SUV, Town Car, Passenger Van, Taxi service from Boston Airport to Hudson MN"
            title="Hudson MA "
            desc1="Acton Airport Taxi provides taxi and limo services in Hudson MA which is reliable and comfortable. You can make a booking from Boston Airport to any cities and from any cities to Boston Airport. We have cabs& car services which suit every pocket from a low-cost car. We have a ride range of vehicles to a luxurious car that suits your requirements to accommodate your family. "
            desc2="We provide taxi cab service in best & low pricing as you can make an online booking with an initial payment of $1 and the due amount can be paid later on arrival with cash or credit card. Acton Airport Taxi provides various payment options like PayPal and Card. We assure you to be picked up and drop off on time at anywhere in Acton, Hudson, Boston and its surrounding areas. We have experienced drivers or chauffeurs waiting for you at the airport terminal along with living ride tracking is one of our feature for the safe ride experience. We make sure our passenger reaches their destination safely. "
          />
        </Route>
        <Route path="/marlborough">
          <ServiceArea
            metatitle="Taxis Marlborough | Marlborough Limos "
            metadesc="Taxi & Limos services in Marlborough, MA. Get a ride to local restaurants, bars, hotels, and convention centers. Call  (978) 268-0700 for quality taxi services anytime anywhere in Marlborough"
            title="Marlborough MA"
            desc1="Acton Airport Taxi is here with best and convenient transportation service within Marlborough MA. Bookings can be done from any cities to Boston Airport and vice-versa. We provide our service on affordable pricing with various range of fleets. The initial payment can be made for $1 after booking your taxi cab or airport shuttle and rest due amount can be paid after the drop off on your locations. "
            desc2="We make sure to meet our customer’s expectations by providing safe pick up and drop off at their desired destination. Payment can be done through PayPal or by card as we have various payment options. Our service is available in Marlborough, Acton, and Hudson and in its surrounding locations. We have hired verified and experienced drivers to provide best and safe Shuttle and Limo service to our customers from the Airport."
          />
        </Route>
        <Route path="/maynard">
          <ServiceArea
            metatitle="Maynard Taxi - $90 Maynard Taxi Service to Boston Airport "
            metadesc="Maynard taxi, airport Limo service. Flat rates and taxi fares. Taxi fare to Boston Logan Airport from Maynard, MA "
            title="Maynard MA"
            desc1="Acton Airport Taxi provides best and convenient taxi and limo transportation service in Maynard MA. There is a ride for every occasion and every budget. You can choose what’s best for you from our various categories of fleets with the best pricing. You can make a booking from Boston Airport to any cities and from any cities to Boston Airport. Passengers can make a booking by our mobile app and website to their preferred date and time around the Maynard, Acton, Boston and its surrounding areas. "
            desc2="Acton provides various payment options like PayPal and Card. Passenger can make an online booking by paying an initial amount of $1 and the remaining amount later on arrival. We have experienced drivers to have a safe ride experience. We make sure our passenger reaches their destination safely."
          />
        </Route>
        <Route path="/natick">
          <ServiceArea
            metatitle="Natick Taxi | Flat Rate $75 Logan, Boston, Airports"
            metadesc="Taxi cab Service and Logan airport transportation that you can depend on for all your transportation needs : Lower rate, better service guaranteed any timeany where."
            title="Natick MA"
            desc1="Acton Airport Taxi provides best and convenient taxi and limo transportation service in Natick MA. There is a ride for every occasion and every budget. Our wide range of cars perfectly suits every customer needs as desired. You can make a booking from Boston Airport to any cities and from any cities to Boston Airport. "
            desc2=" Passengers can make a booking by our mobile app and website to their preferred date and time around the Acton, Natick, Boston and its surrounding areas. We provide various payment options like PayPal and Card. Passenger can make an online booking by paying an initial amount of $1 and the remaining amount later on arrival. We have experienced drivers to have a safe ride experience. We make sure our passenger reaches their destination safely. "
          />
        </Route>
        <Route path="/northborough">
          <ServiceArea
            metatitle="Northborough MA Airport Taxi & Car Service - Acton Airport Taxi"
            metadesc="North Borough's Airport Shuttle & Taxi Service to and from North Borough, Northborough MA Airport Taxi, Northborough MA Taxi, Taxi Service Northborough."
            title="Northborough MA"
            desc1="Acton Airport Taxi provides easy and reliable taxi and limo service in North Borough MA. We have cabs which suit every pocket from low-cost car to a luxurious car. We provide service in best pricing as you can make an online booking with an initial payment of $1 and the due amount can be paid later on arrival."
            desc2="Acton Airport Taxi provides various payment options like PayPal and Card. We assure you to be picked up and drop off on time at anywhere in Acton, North Borough, Boston and its surrounding locations. As our mission is to provide safe and comfortable service to our customers, our drivers are experienced enough for a safe ride to your destinations. We are available 24/7 at your transportation service. "
          />
        </Route>
        <Route path="/sudbury">
          <ServiceArea
            metatitle="Online Booking Sudbury | Book Online"
            metadesc="Acton Taxi is the taxi cab service for Sudbury residents where they can choose for reliable transportation 24/7. We offer direct airport pick-up and drop-off."
            title="Sudbury MA"
            desc1="Acton Airport Taxi provides best and reliable taxi and limo service in Sudbury MA. There is a ride for every occasion and every budget. You can choose what’s best for you from our various categories of fleets with the best & low pricing. Passengers can make a booking by our mobile app or website to their preferred date and time for pick up and drop off from any cities to any airports around the Sudbury, Acton, Boston and its surroundings. "
            desc2="We present a various option for the payment like Card and PayPal.Passenger can make an online booking with an initial payment of $1 and the due amount can be paid later on arrival to the desired location. We offer 24/7 customer service to our passengers whether it’s a change in booking or delayed a flight. We are here to offer a more safe taxi & shuttle service with experienced drivers and help traveller have a stress free and pleasant ride to their desired destinations."
          />
        </Route>
        <Route path="/wayland">
          <ServiceArea
            metatitle="Wayland Taxi by ActonAirportTaxi.com - Wayland, MA to Logan"
            metadesc="Acton Airport taxi offers luxury airport and long-distance transfers in the comfort of Limo Town Cars. We offer a car for hire services, airport transfers, hourly stretch limo as well. "
            title="Wayland MA"
            desc1="Acton Airport Taxi provides easy and reliable taxi and limo service from Boston Airport to Wayland MA. A perfect way to get through your everyday travel needs, daily commute and early morning flight.  With the wide range of taxi cabs, you can choose the best for you within your budget and we will take care of rest. You can make an online booking through our mobile app or website with the affordable pricing from Boston Airport to any cities of MA or vice-versa. Bookings with best fleets are available around the areas of Acton, Wayland, Boston."
            desc2="With our secure payment options, we accept all the credits card payment and PayPal payment. The initial payment can be made for $1 after booking your cab and rest due amount can be paid after the drop off to the location. As our mission is to provide safe and comfortable service to our customers, our drivers are experienced enough for safe ride to your destinations. We are available 24/7 at your transportation service."
          />
        </Route>
        <Route path="/westborough">
          <ServiceArea
            metatitle="Westborough Taxi | Flat Rate $90 Logan, Boston, Airports"
            metadesc="Westborough Taxi Service, Safe, fast and affordable cab to Boston and airports. Free online booking. Check our discounts page"
            title="Westborough MA"
            desc1="Acton Airport Taxi provides taxi and limo services in WestboroughMA which is easy and reliable. Bookings can be done from any cities to Boston Airport and vice-versa. We provide our service on affordable pricing with various range of fleets."
            desc2=" We provide service in best pricing as you can make an online booking with an initial payment of $1 and the due amount can be paid later on arrival. Our fares are fixed and flat from Boston to any locations of Westborough. With our secure payment options, we accept all the credits card payment and PayPal payment. We have experienced drivers to have safe transportation experience. Passengers can make a booking by our mobile app and website to their preferred date and time around the Westborough, Acton, Boston and its surrounding areas. We are available 24/7 for your stress-free and pleasant ride to desired destinations."
          />
        </Route>
        <Route path="/weston">
          <ServiceArea
            metatitle="Weston Airport Taxi | Transfers Services | Boston Limos"
            metadesc="Acton Airport Taxi MA is the best Cab Service in Weston, MA. Our services include Taxi/Cab, Airport Transportation and Car Service to and from the Weston"
            title="Weston MA"
            desc1="Acton Airport Taxi provides easy and reliable taxi and limo service in Weston MA. Our quick and easy to use website allows you to book your cab in real-time with cheap and best price. Our wide range of cars perfectly suits every customer needs as desired. Passenger can make an online booking by paying an initial amount of $1 and the remaining amount later on arrival."
            desc2="We assure you to be picked up and drop off on time at anywhere in Acton, Weston, Boston and its surrounding areas. We offer 24/7 customer service to our passengers whether it’s a change in the booking or delayed a flight.   We ensure you reach your destination on time and in less hassle as we have experienced drivers. We offer 24/7 customer service to our passengers in booking queries."
          />
        </Route>
        <Route path="/boston-airport-taxi">
          <ServiceDetails
            metatitle="Boston Airport Taxi | Low Cost Boston Airport Fares Acton Taxi"
            metadesc="Affordable Boston Taxi, Minivan Child Car Seat to and from Boston Logan airport. Economy, Executive Sedan, Executive Black, etc. serving Boston MA"
            title="Boston Airport Taxi"
            titlesm="Low Cost Luxurious ride in Boston areas"
            desc="Acton Airport Taxi or Shuttle offers Boston or Logan MA airport taxi which provides Luxurious or comfortable taxi or shuttle services to and from Acton MA to Logan international airport. Our flat taxi fare from Acton MA to Logan airport and from Logan airport to Acton MA is affordable and reliable."
            title1="SAFE RIDE"
            desc1="If you are travelling to Boston or Logan or any major cities of MA for any special occasions like wedding,proms or tours or any long distance rides with in the Boston or Logan areas, you need to look up the reliable or safest agency for your travel."
            title2="24/7"
            desc2="Availability of 24/7 with luxurious fleets of car service or taxi cab or passenger minicab service or any airport transfer rides has made the travel easier and faster than any metered taxis in full packed traffic.

            Acton airport taxi offers affordable and best Boston airport Taxi service to all the users around in Acton. We offer reliable and comprehensive services as airport taxi Boston Ma, Car service to Logan airport, Boston airport taxi cab service, Boston Logan taxi, taxi to Logan airport and Boston airport taxi service.
            
            We are the first choice and best taxi service provider 24/7 in Boston. We Operate as Boston Logan Taxi, Boston Yellow Cab, and Yellow taxi Boston as well. Acton airport taxi provides you unparalleled support and excellent airport transportation service to and from Boston Logan Airport."
            title3="TRAFFIC ROUTES"
            desc3="In this, you need to consider the taxicab or car service or any passenger minivan who does not only know about traffics routes also make your more special to make your travel or journey more special to capture the moments forever that makes to travel more often.

            If you are riding to Boston or Logan, Acton Airport Taxi or Shuttle is the best and safest taxi cab company that makes your rides more special right from pick from Boston or Logan International Airport."
            title4="BEST & LOW COST TAXI IN BOSTON"
            desc4="As we understand that the transportation requirement may require any time. Hence we offer advance and instant booking service to our client. With our low cost and affordable taxi service to the airport you can be sure to get the best price for your quoted journey. Unlike other company we don't have any hidden cost or additional charges. We offer our client 45 minutes free waiting time under every airport journey. Since we track all the flights, don’t panic if your flight is delayed. All the driver are dispatched as per the expected arrival of flight, we make sure our clients reach their destination promptly, comfortably along with peace of mind."
            title5="FLEET"
            desc5="ur fleet features 3/4 Passenger Luxury Sedans, 6/7 Passenger Minivans, and 5/7 Passenger SUVs which are capable of providing you with executive business travel experience. With our experienced and licensed drivers who are well known with the city and short routes, you can be assure of getting into your airport on time and safely."
            title6="DRIVERS"
            desc6="All our drivers are smart and experienced. They will be waiting inside their terminal along with your nameplate. Don't worry about the delay as we will be monitoring the flight schedule. Our driver will assist you with your luggage and will take to your destination safely and on time. Our drivers will pick up from your locations and drop off on your destination safely."
            title7="HOW TO BOOK"
            desc7="You can book the taxi or shuttle from the left side of the website with your details and once we received your details we will get back to you with a few mins or so for your luxury ride."
            title8="PAYMENT OPTIONS"
            desc8="All of your booking's payments are processed via secured payment solutions via Card or PayPal"
            boldtitle1="Estimate Your Airport Taxi Cab Fare, Cost & Rates in Boston."
            boldtitle2="Call us today on (978) 268-0700 to book your journey."
          />
        </Route>
        <Route path="/boston-airport-shuttle">
          <ServiceDetails
            metatitle="Boston Airport Shuttle | Boston Car Service"
            metadesc="Your private car service to and from Boston Logan International Airport (BOS) or around town in the Boston area. Black cars, sedans, and SUVs available."
            title="Boston Airport Shuttle"
            titlesm="Luxurious shuttle ride in Boston areas"
            desc=""
            title1=" RELIABLE & TRUSTABLE"
            desc1="Acton Airport taxi or shuttle offers 24/7 reliable and comfortable ride to or from Boston or Logan International Airport (BOS) with more friendly professional drivers or chauffeurs and will pick up from your location before time.

            Get constant and nonstop Boston airport shuttle service to and from Boston to Acton from the Boston most trusted Boston airport Taxi Company with Low Cost Fares. Acton Airport Shuttle is one of the most reliable and affordable transportation options for any ground transportation."
            title2="LUXURY RIDE"
            desc2="Either you are traveling with groups or family or individual, we make your travel a most luxurious and more memorable for you to capture."
            title3="COVERAGE"
            desc3="Our shuttle available from any major place or cities of Boston, MA and surrounding suburbs (Ipswich, Andover, Reading) from your location from Acton Airport Taxi."
            title4="TRAFFIC"
            desc4="There are multiple ways of getting to or from Boston or Logan International airport either metered taxis or passenger minivans or Yellow Cab or Shared ride shuttle service any airport transfer rides.

            Boston is Massachusetts’ capital and largest city. Boston is a historic place that offers visitors to relive history, immerse themselves in the arts, cheer for hometown sports teams, and explore museums and many more. It is a perfect place and includes a must-visit place too. You can simply take a break from your busy life schedule and explore the hidden treasure of Boston."
            title5="LOW PRICE"
            desc5="Our prices are very competitive and low charges than any car services or yellow Cab or metered taxis or passenger minivans and there is no upfront payment although you get the upfront fares when you book a shuttle for your ride."
            title6="PICK UP & DROP OFF"
            desc6="We strive to best and safety first ride with a luxurious ride where we will pick you from your location at the right time to your drop off locations.

            Our shuttle is available 24/7 and you can reserve an Airport Shuttle in or around Boston major cities via online reservation or App and there is no waiting time for your pick up, we arrive 30 mins before we pick up you up from your locations.
            
            With our convenient, affordable and professional 24/7 airport transfer service, you can be assure to be picked up and drop off from the airports always on time. After booking, the system will send you the confirmation email along with the booking details. Once the job is dispatch then you will receive the details of driver on email and via direct SMS as well. Our driver will be waiting to take you anywhere any time in Boston. As we track all the flight status you don’t have to wait at terminals, our drivers will reach at your desired terminal with the updated time table.
            
            Timely Airport Drop-Off & Pick-Up Service from our professional drivers. Call Us Today at (978) 268-0700 for quick Reservations. Available 24/7 shuttle service."
            title7="DISCOUNT VOUCHOR"
            desc7="Pre-booking available. 5-10% Senior discount. Online booking option with payment options available. Book your taxi at $1 and rest after the completion of the journey."
            title8="HOW TO BOOK"
            desc8="You can book the taxi or shuttle from the left side of the booking form in the website with your details and once we received your details we will get back to you with a few mins or so for your luxury ride.

            Reserve your Boston car service with us today. As we provide the service surround all areas and town of Boston, you can simply google with the best airport limo near me or taxi service near me keyword and click on the website www.actonairporttaxi.com and make a booking in few clicks and make a luxurious ride with Acton Airport Taxi anywhere and anytime in Boston"
            title9="PAYMENT OPTIONS"
            desc9="All of your booking's payment is processed via secured payment solutions via Card or PayPal"
            title10=""
            desc10=""
            boldtitle1="Getting to and From Logan Airport in Boston"
            boldtitle2="Call us today on (978) 268-0700 to book your journey."
          />
        </Route>
        <Route path="/acton-ma-to-boston-airport-transfer">
          <ServiceDetails
            metatitle="Acton MA Airport Taxi & Transfer to Boston or Logan  Airport "
            metadesc="Acton Airport Taxi offers Acton MA airport taxi which provides airport shuttle services to and from Acton MA to Logan international airport."
            title="Acton to Boston Logan Airport"
            titlesm=""
            desc="Acton Airport Taxi offers Acton MA airport taxi which provides convenient and on time airport shuttle services to and from Acton MA to Logan international airport. Our flat taxi fare from Acton MA to Logan airport and from Logan airport to Acton MA is affordable and reliable."
            title1=""
            desc1="Our wide range of vehicles including Premium sedan, SUV, 4-6 seater Minivan and Van can accommodate any small or large group from Acton MA to Boston Logan International Airport in a relax and comfort way. All our drivers are smart and experienced. They will be waiting inside their terminal along with your nameplate. Don't worry about the delay as we will be monitoring the flight schedule. Our driver will assist you with your luggage and will take to your destination safely and on time."
            title2=""
            desc2="Acton Airport Taxi is an acton ma taxi service with ground transportation service provider in Boston, MA which offers Acton MA airport taxi to any parts of the Boston which provides convenient and on-time airport shuttle or taxi services to and from Acton MA to Logan international airport. Our flat taxi fare from Acton MA to or from Boston or Logan airport and from Logan airport to Acton MA is much more with fixed rates"
            title3=""
            desc3="Boston's Premier ground Transportation Company operating for more than 15 years around the Clock 365 days a year where we offer 24/7 365 days taxi service. In any circumstances, if you need a taxi cab for you to pick up to a more residential or commercial spot in Boston or Logan areas, Acton Airport Taxi with contact no (978) 268-0700 is ready to assist you for your any kind of events. We have got you covered with our wide range of selection of vehicle choices."
            title4=""
            desc4="We are taxi services near Boston or Logan airports which accommodates the passengers with a variety of taxi fleets from or to Boston or Logan Our fleets have a variety of features including baby seats and free Wi-Fi with experienced drivers."
            title5=""
            desc5="Our wide range of vehicles including Premium sedan, SUV, 4-6 seater Minivan and Van can accommodate any small or large group from Acton MA to Boston Logan International Airport in a relax and comfort way. All our drivers are smart and experienced. They will be waiting inside their terminal along with your nameplate. Don't worry about the delay as we will be monitoring the flight schedule. Our driver will assist you with your luggage and will take to your destination safely and on time."
            title6=""
            desc6="Do you want to schedule or pre-book taxis your journey or any special events in Boston or Logan? Acton Airport Taxi comes into effect to make your events or journey brighter and more enjoying."
            title7=""
            desc7="If you are looking for airport taxi or transportation or airport taxi ride near Boston or your nearby Locations to Boston or Logan areas,you can contact Acton Airport Taxi directly to this number (978) 268-0700 or email to info@actonairporttaxi.com."
            title8=""
            desc8="Call Acton Airport Taxi & car service on (978) 268-0700 and book your affordable Boston taxi cab service or Logan airport Taxi service with child or baby seat. Be sure all will be accommodated with our luxurious fleets"
            title9=""
            desc9=""
            title10=""
            desc10=""
            boldtitle1="Affordable Taxi Cab Service in Acton, MA to Boston"
            boldtitle2="Call us today on (978) 268-0700 to book your journey."
          />
        </Route>
        <Route path="/acton-ma-to-boston-logan-airport-international-airport">
          <ServiceDetails
            metatitle="Acton MA Airport Taxi to Boston Logan Airport Transfer "
            metadesc="If you are looking for a taxi service from Acton MA to Logan International Airport. Acton Airport taxi is providing affordable & professional service."
            title="Acton MA to Boston Logan Airport International Airport"
            titlesm=""
            desc="If you are looking for Boston Logan airport shuttle service from Acton MA to Boston Airport Transfer then always remember Acton airport taxi. Acton airport taxi is providing airport taxi service since 1989 and is regularly operating its reliability and on time airport service to and from Acton MA to Boston Logan Airport International Airport, Acton MA to Logan Airport at most affordable rates."
            title1=""
            desc1="Our Boston airport transfer service is perfect for any of the corporate or executive travelers searching for a comfortable and luxurious ride."
            title2=""
            desc2="If you are looking for Boston or Logan airport shuttle service, then you are at the right website. If you are travelling from Acton MA to Boston or Logan International Airport then always remember Acton airport taxi which has been providing the most reliable and affordable cost of a ride to your destination from Boston or Logan Airport to anywhere in Boston areas. Acton airport taxi is providing airport taxi service near Boston or Logan Airport since 1989 and is regularly operating its reliability and on-time airport service to and from Acton MA to Boston or Logan Airport International Airport, Acton MA to Logan Airport at most affordable rates."
            title3=""
            desc3="We have a wide range of fleets available for your luxurious ride. Our fleets consist of Mercedes, Sedan and SUV premier class vehicles for your ride from Boston International airport to any locations of Boston areas. We are everywhere at any time any place in Boston city. We are located near your locations and just a call away for your next ride. Book your next ride with Acton Airport Taxi in Boston and Airport Locations."
            title4=""
            desc4="Our prices are fixed and save more than 30% than metered taxis which are easily available in the Airports. We do not charge extra for your journey and we are available for pre-booking as well. You can prebook your journey and be tense free from the ride. We will reach your doorstep in the preferred date and time anywhere in Boston state."
            title5=""
            desc5="Our drivers are very professional and experienced where most of them having driving experience of more than 20 years in the field and known about the routes of a traffic-less route to your drop off locations."
            title6=""
            desc6="Our Boston airport transfer service is perfect for any of the corporate or executive travellers searching for a comfortable and luxurious ride."
            title7=""
            desc7=""
            title8=""
            desc8=""
            title9=""
            desc9=""
            title10=""
            desc10=""
            boldtitle1="Call us today to book your journey from Acton MA to Boston Airport at (978) 268-0700."
            boldtitle2=""
          />
        </Route>
        <Route path="/acton-ma-to-logan-airport-transfer">
          <ServiceDetails
            metatitle="Acton Airport Taxi  Service | To Logan International Airport  "
            metadesc="Highly reliable & comfortable Logan airport transfer services at affordable prices. Airport transfer service from Acton MA to Logan International Airport"
            title="Acton MA to Boston Logan Airport International Airport"
            titlesm=""
            desc="Acton Airport Taxi offers Acton MA airport taxi which provides convenient and on-time airport shuttle services to and from Acton MA to Logan international airport. Our flat taxi fare from Acton MA to Logan airport and from Logan airport to Acton MA is affordable and reliable."
            title1=""
            desc1="Acton Airport Taxi is an Acton ma taxi service with ground transportation service provider in Boston, MA which offers Acton MA airport taxi to any parts of the Boston which provides convenient and on-time airport shuttle or taxi services to and from Acton MA to Logan international airport. Our Boston flat taxi fare from Acton MA to or from Boston or Logan airport and from Logan airport to Acton MA is much more with fixed rates"
            title2="OUR EXPERIENCE"
            desc2="Boston's Premier ground Transportation Company operating for more than 25 years around the Clock 365 days a year where we offer 24/7 365 days taxi service. In any circumstances, if you need a taxi cab for you to pick up to a more residential or commercial spot in Boston or Logan areas, Acton Airport Taxi with contact no (978) 268-0700 is ready to assist you for your any kind of events. We have got you covered with our wide range of selection of vehicle choices."
            title3=""
            desc3="With over 25 years of experience, you can place your confidence in our ability to get you from Acton ma to Logan airport safely and on time. Our drivers will pick you from the Logan Airport and transfer you to your agreed drop off destination. Our Acton Ma to Logan airport transfer is rated as best transportation service by our regular passengers. Call us today to book your cab with us or you can also make a reservation online. With our easy, user-friendly and secure booking system, you can simply make the advance or instant booking in few clicks. As soon as the booking is confirmed, you will receive the booking confirmation email along with the driver details via email and direct SMS. For more enquiry about Acton airport booking service, call us at 617-499-7772. Our dedicated staff is always there to assist you with your queries."
            title4="TAXI NEAR BOSTON"
            desc4="We are taxi services near Boston or Logan airports which accommodates the passengers with a variety of taxi fleets from or to Boston or Logan Our fleets have a variety of features including baby seats and free Wi-Fi with experienced drivers."
            title5="OUR FLEETS"
            desc5="Our wide range of vehicles including Premium sedan, SUV, 4-6 seater Minivan and Van can accommodate any small or large group from Acton MA to Boston Logan International Airport in a relax and comfort way. All our drivers are smart and experienced. They will be waiting inside their terminal along with your nameplate. Don't worry about the delay as we will be monitoring the flight schedule. Our driver will assist you with your luggage and will take to your destination safely and on time."
            title6=" ANYWHERE AND ANYTIME IN BOSTON"
            desc6="Logan Airport Car Service provides the best and flat rate Logan airport services to and from anywhere on the Logan areas. We offer reliable and professional services such as taxi Boston to Logan airport, taxi service from Logan airport, Boston Logan cab service, Logan airport limousine service, Boston Logan airport transportation to downtown, Boston Logan airport transportation service and Logan airport shuttle service."
            title7=" BOOK YOUR TAXIS "
            desc7="Do you want to schedule or pre-book taxis your journey or any special events in Boston or Logan? Acton Airport Taxi comes into effect to make your events or journey brighter and more enjoying."
            title8=""
            desc8="If you are looking for airport taxi or transportation or airport taxi ride near Boston or your nearby Locations to Boston or Logan areas, you can contact Acton Airport Taxi directly to this number (978) 268-0700 or email to info@actonairporttaxi.com."
            title9=""
            desc9=""
            title10=""
            desc10=""
            boldtitle1="Call Acton Airport Taxi & car service on (978) 268-0700 and book your affordable Boston taxi cab service or Logan airport Taxi service with child or baby seat. Be sure all will be accommodated with our luxurious fleets"
            boldtitle2=""
          />
        </Route>

        <Route path="/terms-and-conditions">
          <Terms />
        </Route>
        <Route path="/privacy-policy">
          <Privacy />
        </Route>
        <Route path="/">
          <Index />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
