import React from "react";
import Nav from "../components/NavComp";
import InnerBanner from "../components/InnerBanner";
import { Col, Container, Row } from "react-bootstrap";
import Banner from "../images/about-banner.png";
import FooterComp from "../components/FooterComp";

import ListGroup from "react-bootstrap/ListGroup";
const About = () => {
  return (
    <>
      <Nav
        title="Acton to Logan airport taxi services – Acton Airport Taxi"
        desc="Reliable taxi services with affordable fares from Acton to Logan International airport in Massachusetts. Unbeatable prices and services we offer."
      />
      <InnerBanner BannerImage={Banner} />
      <div className="section__gap ">
        <Container fluid>
          <h2 className=" text-center section__title centered hav__icon mb-5">
            About Us
          </h2>
          <Row>
            <Col lg="12">
              <h4 className="mb-4   ">
                <strong> Getting to and From Logan Airport in Boston</strong>
              </h4>
              <p>
                Are you looking for a reliable
                <strong> Boston cab companies </strong>
                to the airport in Acton. Your search is over as
                <strong> Acton airport taxi </strong> provides the
                <strong> best Boston cab fares</strong> and professional car
                services to client.
              </p>
              <h4 className="mb-4  md__title ">LOW COST BOOKING</h4>
              <p>
                Acton is a suburban town in Middlesex County, Massachusetts,
                United States, approximately twenty-one miles west-northwest of
                Boston.
              </p>
              <p>
                {" "}
                If you are looking for a taxi service from Acton MA to Boston or
                Logan International Airport, Boston Airport, Manchester NH,
                Portland International Jetport, Providence TF Green, Bradley CT
                International, and Burlington VT Airport then you are in the
                right place. Acton Airport taxi is providing transportation from{" "}
                <strong>
                  Acton MA to Boston International Airport
                </strong> and <strong>Acton MA to Logan Airport</strong> at a
                very affordable price for several years. Our prices are as low
                as $120 from Acton to Boston or Logan International Airport.
              </p>
              <h4 className="mb-4  md__title ">OUR FLEET</h4>
              <p>
                Our quality range of <strong>Boston Airport fleets</strong>{" "}
                includes Premium Sedan, 2 to 3 premium sedan, 6 to 7 passenger
                minivan, 4 passenger minivan, 3 passenger SUV, 6 passenger
                Minivan and 10 to 12 passenger group van. We deliver high
                quality,{" "}
                <strong>
                  affordable taxi service from ActonMa to Boston Logan Airport
                </strong>
                , <strong>from Boston Logan Airport to Acton</strong> and
                surrounding town and areas of Acton. Our west Acton cabs
                service, limo service, private hire, chauffeur car service is
                available for any occasion such as birthday parties, weddings,
                anniversaries and graduations.
              </p>
              <h4 className="mb-4  md__title ">SERVICE COVERAGE</h4>
              <p>
                We are specialized in providing the best ground transportation
                services for any event or occasion such as airport transfer
                (Acton MA to Boston International Airport, Acton MA to Logan
                Airport, Acton to Manchester, Acton to Portland ME International
                Jetport, Acton to Providence RI TF Green/Warwick,Acton to
                Bradley CT Intl,Acton to Burlington VT Airport) corporate
                events,Sightseeing tours, Theaters, Birthday parties, Long
                distance tour, Wedding parties.
              </p>
              <p>
                Acton is a town in Middlesex County, Massachusetts which is
                approximately 21 miles west-northwest of Boston and west of
                Concord. Some of the popular outdoor areas are Acton arboretum,
                Conservation lands, Nara Park, Discovery Museum, Minute Man
                National Historic Park. Whether you come here for holiday or
                business purpose, you can use your time as best as possible to
                enjoy these scenic beauty.
              </p>
              <h4 className="mb-4  md__title ">
                TRAVELING TO BOSTON OR LOGAN?
              </h4>
              <p>
                Traveling to Boston or Logan from your location? Your taxi
                provider making to wait for long in the Boston or Logan
                International airport with no reason? Are you waiting for your
                taxi cab after a long airport ride and transit to Boston or
                Logan International Airport?
              </p>
              <p>
                Traveling is for fun not for hectic and traveling for special
                occasions like wedding, events or shopping or tour is more than
                fun. Acton Airport Taxi or Shuttle makes your travel more than
                fun with executive travel or memorable with a comfortable ride
                and with no hectic to remember to.
              </p>
              <h4 className="mb-4  md__title ">WEBSITE OR MOBILE APP</h4>
              <p>
                We are the most trusted and secured taxi cab service nearby you
                provides Boston and logan or other popular cities in MA.
              </p>
              <p>
                Just a quick click from your mobile app or the website to the
                nearby taxi waiting for you at the airport with your name on the
                board with competitive and low fares metered taxis or Uber taxis
                or yellow cab or any car service available in Boston or Logan
                areas.You will have taxi or shuttle transportation options to
                choose Acton Airport Taxi from many which have to offer for your
                comfortable ride in Boston or Logan areas with free refreshment
                for your taxi travel to or from boston airport to your
                destination
              </p>
              <p>
                Acton Airport <b>Taxi</b> or <b>Shuttle</b> is the best{" "}
                <b>Boston</b> or <b>Logan Airport Taxi</b> provider which is the
                safest way to get to or from Boston or Logan international
                airport{" "}
              </p>
              <p>
                All our drivers are experienced, professional, courteous and
                understand the importance of time. So while you travel with us
                take the stress out and just relax as we ensure that you will
                reach your destination on time always.
              </p>
              <p>
                From flying out to returning home, a resident or a visitor in
                Acton, whether it is airport pickup or drop off, our Acton Ma to
                Logan Airport taxi service is always available at your disposal.
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg="12" className="mt-5">
              <h4 className="mb-4   md__title ">Features</h4>
              <ListGroup className="  diamond-list">
                <ListGroup.Item>
                  Acton, MA to Logan Airport taxi cab, town car, SUV and
                  Limousine service.
                </ListGroup.Item>
                <ListGroup.Item>
                  Logan Airport to Acton, MA taxi cab, town car, SUV and
                  Limousine service.
                </ListGroup.Item>
                <ListGroup.Item>
                  Child and Booster seat available for your baby and kids.
                </ListGroup.Item>
                <ListGroup.Item>
                  Better rate compared to any other company and services.
                </ListGroup.Item>
                <ListGroup.Item>
                  We bet price for with others for the same type of services.
                </ListGroup.Item>
                <ListGroup.Item>
                  All of your booked cars will arrive at you on time.
                </ListGroup.Item>
                <ListGroup.Item>
                  Easy booking without any type of hassle.
                </ListGroup.Item>
                <ListGroup.Item>
                  Safe way to get to or from Boston or Logan International
                  Airport.
                </ListGroup.Item>
                <ListGroup.Item>
                  Taxi or Shuttle available near you at Boston or Logan areas at
                  a click of a button.
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </Container>
      </div>

      <FooterComp />
    </>
  );
};
export default About;
