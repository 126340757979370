import React from "react";
import Nav from "../components/NavComp";
import InnerBanner from "../components/InnerBanner";
import { Col, Container, Row } from "react-bootstrap";
import Banner from "../images/about-banner.png";
import FooterComp from "../components/FooterComp";

const Privacy = () => {
  return (
    <>
      <Nav
        title="PRIVACY POLICY | Acton MA to Boston Airport Transfer"
        desc="Acton Taxi Service is providing an affordable & reliable pick and drop taxi service from Acton MA to Logan airport transfer. Go through our Privacy Policy"
      />
      <InnerBanner BannerImage={Banner} />
      <div className="section__gap contact__page">
        <Container fluid>
          <Row>
            <Col lg="12">
              <h2 className=" text-center centered section__title hav__icon mb-5">
                Privacy Policy
              </h2>
              <p>Acton Airport Taxi & car Services is operated & managed by Logan Airport Car, Inc. and Customer Privacy is most Priority and we would be glad if you can spare some time and go through our privacy policy and terms of use. Logan Airport Car Inc. is very concerned about your privacy and assures you that we exceed industry standards in safeguarding your personal details and identity information that you have ever shared with us.</p>
              <h4 class="mb-2 mt-4  md__title ">Tracking Traffic on our sites </h4>
              <p>
              To track our web traffic we use third party software form Google that is fairly open standard. So your geolocation and other site usage are tracked but the user remains anonymous all the time. We use this data for our internal reporting purposes.
              </p>
              <h4 class="mb-2 mt-4  md__title ">Use of Cookies and IP logging </h4>
              <p>
              On our blog that runs WordPress, a certain plug-in might use cookies or log your IP for safety and anti-fraud techniques. We assure you that they are all open standard methods and used by most of the websites and blogs today. So you are in safe hands. When you submit a request we might track your IP as well to validate the request and avoid our spamming of our sites.
              </p>
              <h4 class="mb-2 mt-4  md__title ">Use of Personal Information </h4>
              <p>
              We sometimes ask you for personal information like email, Name, address & phone number while commenting on the blogs or sending a contact/query request. While Email data is not public and your name and address that you share are public on comments. Under no circumstances will these protected data be shared with a third entity. (Refer to spamming cases)
              </p>
              <h4 class="mb-2 mt-4  md__title ">Spamming our Site </h4>
              <p>
              When our automated spam detection system detects your comments or queries as spam we might report your IP and email address for blacklisting. This is a global practice to nail the spam demon.
              </p>
              <h4 class="mb-2 mt-4  md__title ">Content on Site </h4>
              <p>
              We make sure the content on our site is original. Whenever we use graphics by other creators we make sure to provide appropriate credentials. In case you find some of them lacking a credit or if you find your work on our site and do not approve it then let us know we shall remove it within 48 hours.
              </p>
               
            </Col>
          </Row>
        </Container>
      </div>

      <FooterComp />
    </>
  );
};

export default Privacy;
