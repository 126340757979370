import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import Img1 from "../images/suv.png";
import Img2 from "../images/sedan.png";
import Img3 from "../images/compact.png";

const ThreeColWithImage = ({ Data = null, SectionTitle }) => {
  const defaultData = [
    {
      title: "COMPACT SEDAN",
      description: "Compact Sedan for up-to 2-Passenger",
      img: Img1,
      rating: "5.0",
      price: "$250",
      url: '/our-fleets',
    },
    {
      title: "3-PAX SEDAN",
      description: "Compact Sedan for up-to 2-Passenger",
      img: Img2,
      rating: "5.0",
      price: "$460",
      url: '/our-fleets',
    },
    {
      title: "6/7-PAX MINIVAN",
      description: "Compact Sedan for up-to 2-Passenger",
      img: Img3,
      rating: "4.5",
      price: "$670",
      url: '/our-fleets',
    },
  ];

  if (!Data) Data = defaultData;
  return (
    <div className="section__gap threecolwithimage__wrapper ">
      <Container fluid>
        <div className="text-center">
          {SectionTitle && <h4 className="small__title">{SectionTitle}</h4>}
          <h2 className="section__title">
            <span> Trending </span> cars great and <br /> fast this week
          </h2>
          <p>
            Our quality range of Boston Airport fleets includes Premium Sedan, 2
            to 3 premium sedan, 6 to 7 passenger minivan, 4 passenger minivan, 3
            passenger SUV, 6 passenger Minivan and 10 to 12 passenger group van.
          </p>
        </div>
        <div className="text-center">
          <Tabs defaultActiveKey="recommended">
            <Tab eventKey="recommended" title="Recommended">
              <Row>
                {Data.map((info, i) => (
                  <Col className="threecolwithimage__unit" xs lg="4" key={i}>
                    <div className="rating__wrap">
                      <i className="fa fa-star"></i> {info.rating}
                    </div>
                    <div>
                      <img src={info.img} alt={info.title || ""} />
                      <h4>{info.title || ""}</h4>
                      <p className="description">{info.description || ""}</p>
                      <div className="price-box">
                        <h5>{info.price}</h5>
                        <Button href={info.url} className="btn__main">View More</Button>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </Tab>
            <Tab eventKey="trending" title="Trending">
              <Row>
                {Data.map((info, i) => (
                  <Col className="threecolwithimage__unit" xs lg="4" key={i}>
                    <div className="rating__wrap">
                      <i className="fa fa-star"></i> {info.rating}
                    </div>
                    <div>
                      <img src={info.img} alt={info.title || ""} />
                      <h4>{info.title || ""}</h4>
                      <p className="description">{info.description || ""}</p>
                      <div className="price-box">
                        <h5>{info.price}</h5>
                        <Button className="btn__main">View More</Button>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </Tab>
            <Tab eventKey="frequentlyvisited" title="Frequently Visited">
              <Row>
                {Data.map((info, i) => (
                  <Col className="threecolwithimage__unit" xs lg="4" key={i}>
                    <div className="rating__wrap">
                      <i className="fa fa-star"></i> {info.rating}
                    </div>
                    <div>
                      <img src={info.img} alt={info.title || ""} />
                      <h4>{info.title || ""}</h4>
                      <p className="description">{info.description || ""}</p>
                      <div className="price-box">
                        <h5>{info.price}</h5>
                        <Button className="btn__main">View More</Button>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </Tab>
          </Tabs>
        </div>
      </Container>
    </div>
  );
};

export default ThreeColWithImage;
